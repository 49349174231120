import 'bootstrap';
import DynamicLoad from './lib/DynamicLoad';
import './lib/HeaderLinker';
import './lib/Sidebar';
import './lib/TableOfContents';

$(document).on('page.willUpdate', function(event) {
  $('[data-toggle="tooltip"]').tooltip('dispose');
});

$(document).on('page.didUpdate', function(event) {
  $('[data-toggle="tooltip"]').tooltip();
});

$(function() {
  new DynamicLoad().registerHandlers();

  $(document).trigger('page.didUpdate');
});